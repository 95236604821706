///////////////////////
// Breakpoints
$screen-sm-min: 576; // Small tablets and large smartphones (landscape view)
$screen-md-min: 768; // Small tablets (portrait view)
$screen-lg-min: 992; // Tablets and small desktops
$screen-xl-min: 1200; // Large tablets and desktops

$max_width_wrapper: 1440px;
$timming: .2s;

$white: #fff;
$black: #000;
$gray-darker: #1F292A;
$gray-lighter: #f1f1f1;
$gray-light: #C4C4C4;
$gray: #6F6F6F;
$yellow: #FFFF00;
$red: #ff0000;
$light-red: #EF4044;
$green: #2CDB87;

// Misc
$block-list-item: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7214 4.61415L11.5263 0.138499C11.2872 -0.0672882 10.9279 -0.0406405 10.723 0.197719C10.5167 0.436078 10.5434 0.794345 10.7809 1.00014L14.7643 4.43043L0.570138 4.42895C0.255376 4.42895 0 4.68359 0 4.99745C0 5.31131 0.255376 5.56596 0.570138 5.56596L14.9068 5.56596L12.6367 7.58681L10.8639 8.98586C10.6174 9.18128 10.5743 9.53807 10.7703 9.78385C10.8832 9.92598 11.0495 10 11.2187 10C11.342 10 11.4667 9.96003 11.5721 9.87712L13.3716 8.45733L16.7315 5.46973C16.8533 5.36165 16.9231 5.20472 16.9231 5.04039C16.9171 4.87606 16.8459 4.72209 16.7212 4.61401L16.7214 4.61415Z' fill='black'/%3E%3C/svg%3E%0A");