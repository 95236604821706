* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: 'Switzer', sans-serif;

    @include font-size(20, 34);

    @include device-xl {
        @include font-size(18, 32);
    }

    @include device-sm {
        @include font-size(16, 28);
    }
}

.wrapper {
    display: block;
    width: 100%;
    position: relative;
    max-width: $max_width_wrapper;
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px;

    @include device-lg {
        padding-left: 32px;
        padding-right: 32px;
    }
    @include device-md {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.section {
    padding-top: 120px;
    padding-bottom: 120px;

    &:first-of-type {
        padding-top: 380px;
    }

    @include device-md {
        padding-top: 80px;
        padding-bottom: 80px;

        &:first-of-type {
            padding-top: 260px;
        }
    }
    
    &--work-case{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

strong {
    font-weight: 600;
}

p {
    margin-bottom: 2em;
}

.feature-list-title {
    @include font-size(40, 50, 400, -2);

    @include device-xl {
        @include font-size(34, 40, 400, -2);
    }

    @include device-sm {
        @include font-size(30, 36, 400, -2);
    }
    &--small {
        @include device-sm {
            @include font-size(24, 30, 400, -2);
        }
    }
}

.big-list-title {
    @include font-size(60, 70, 400, -3);

    @include device-xl {
        @include font-size(40, 50, 400, -2);
    }

    @include device-sm {
        @include font-size(24, 30, 400, -2);
    }
}

blockquote {
    @include font-size(60, 70, 400, -3);
    @include device-xl {
        @include font-size(40, 50, 400, -2);
    }
    @include device-sm {
        @include font-size(24, 30, 400, -2);
    }
}