.btn {
    &-text {
        color: inherit;
        text-decoration: underline;
    }

    &-box {
        display: inline-block;
        @include font-size(18, 18, 600);
        color: $white;
        background-color: $black;
        padding: 20px 40px;
        text-decoration: none;
        border: 2px solid $black;
        cursor: pointer;
        transition: color $timming ease-in-out,
            background-color $timming ease-in-out,
            border-color $timming ease-in-out;

        &:hover, &:focus-within {
            color: $black;
            background-color: $yellow;
        }

        &.--loading {
            position: relative;
            color: transparent;
            pointer-events: none;

            .spinner {
                display: block;
                color: $white;
            }
        }

        &.--secondary {
            background-color: $white;
            color: $black;
            border-color: $gray-lighter;

            &:hover, &:focus-within {
                border-color: $black;
            }
        }

        @include device-md {
            @include font-size(16, 16, 600);
            padding: 13px 24px;
        }
    }

    &-chevron {
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        @include font-size(20, 36);
        color: inherit;
        text-decoration: underline;
        text-transform: uppercase;
        transition: background-color $timming ease-in-out,
            color $timming ease-in-out, padding $timming ease-in-out;

        @include device-md {
            @include font-size(16, 26);
        }

        &::after {
            content: "";
            display: inline-block;
            width: 0.45em;
            height: 0.45em;
            border-top: 2px solid $black;
            border-right: 2px solid $black;
            transform-origin: center;
            transform: rotate(45deg);
            opacity: 0;
            transition: opacity $timming ease-in-out;
        }

        &:hover, &:focus-within {
            color: $black;
            background-color: $yellow;
            padding-left: 8px;
            padding-right: 10px;
            text-decoration: none;

            &::after {
                opacity: 1;
            }
        }
    }

    &-arrow {
        display: grid;
        grid-template-columns: auto 125px;
        gap: 12px;
        align-items: center;
        width: auto;
        min-height: 80px;
        border: 0;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        color: inherit;
        text-decoration: none;
        background: transparent;
        outline: none;
        padding: 0;
        margin: 0;
        @include font-size(20, 24, 400);
        transition: background-color $timming ease-in-out,
            padding $timming ease-in-out, color $timming ease-in-out;

        $btn-arrow: &;

        &__title {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &__icons {
            position: relative;
            height: 100%;
            text-align: right;
            transition: opacity $timming ease-in-out;

            .icon__arrow {
                display: block;
                width: 36px;
                height: 22px;
                margin-top: -11px;
                position: absolute;
                top: 50%;
                right: 0;
                transition: opacity $timming ease-in-out;
            }

            i {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-end;
                height: 100%;
                @include font-size(16, 20);
                text-decoration: underline;
                opacity: 0;
                transition: opacity $timming ease-in-out;

                .icon__external {
                    display: none;
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                    opacity: 1;
                    vertical-align: -0.3em;
                }
            }
        }

        &:hover, &:focus-within {
            background-color: $yellow;
            padding-left: 16px;
            padding-right: 16px;
            color: $black;

            #{$btn-arrow}__title {
                .tag-rounded {
                    background-color: $white;
                }
            }

            #{$btn-arrow}__icons {
                .icon__arrow {
                    opacity: 0;
                }
                i {
                    opacity: 1;
                }
            }
        }

        &[target="_blank"] {
            #{$btn-arrow}__icons {
                i {
                    .icon__external {
                        display: inline-block;
                    }
                }
            }
        }

        @include device-md {
            @include font-size(16, 18, 400);
            grid-template-columns: auto 50px;
            min-height: 60px;

            &__icons {
                .icon__arrow {
                    margin-top: -8px;
                    width: 27px;
                    height: 16px;
                }

                i {
                    @include font-size(14, 14, 400);
                }
            }

            &:hover, &:focus-within {
                #{$btn-arrow}__icons {
                    opacity: 0;

                    i {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &-stripe {
        display: grid;
        grid-template-columns: auto 125px;
        gap: 12px;
        align-items: center;
        width: auto;
        color: inherit;
        text-decoration: none;
        outline: none;
        $btn-stripe: &;

        @include device-md {
            @include font-size(16, 18, 400);
            grid-template-columns: auto 50px;
            .btn-line__label {
                @include font-size(14, 14, 400);
            }
        }

        &__title {
            align-items: center;
            display: flex;
            align-items: center;
            position: relative;
            // text-indent: 0;
            padding-left: 0;
            transition: all $timming ease-in-out;
            &::before {
                content: "";
                width: 0;
                margin-right: 0;
                border: none;
                border-top: 2px solid $black;
                height: 2px;
                position: absolute;
                left: 0;
                top: 50%;
                transition: all $timming ease-in-out;
            }
        }

        &__icons {
            position: relative;
            height: 100%;
            text-align: right;
            transition: opacity $timming ease-in-out;
            .icon__arrow {
                text-align: right;
                fill: none;
                display: block;
                width: 36px;
                height: 22px;
                margin-top: -11px;
                position: absolute;
                top: 50%;
                right: 0;
                transition: opacity 0.2s ease-in-out;
                @include device-md {
                    margin-top: -8px;
                    width: 27px;
                    height: 16px;
                }
                @include device-xl("min") {
                    display: none;
                }
            }
        }
        &__label {
            @include device-xl {
                display: none;
            }
            @include font-size(16, 20);
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            text-decoration: underline;
            text-transform: uppercase;
            opacity: 0;
            padding-right: 0;
            transition: all $timming ease-in-out;
        }

        &:hover, &:focus-within {
            @include device-md("min") {
                #{$btn-stripe}__title {
                    padding-left: 74px;
                    @include device-xl("min") {
                        padding-left: 110px;
                    }
                    &::before {
                        width: 54px;
                        margin-right: 20px;
                        @include device-xl("min") {
                            width: 70px;
                        }
                    }
                }
                #{$btn-stripe}__label {
                    padding-right: 16px;
                    opacity: 1;
                }
            }
        }
    }
}

.btn__list {
    border-top: 1px solid $black;

    .btn {
        &-arrow {
            border-top: 0;
        }
    }

    &-big {
        display: block;
        li {
            margin-bottom: 16px;
            @include device-xl("min") {
                margin-bottom: 24px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include device-xl("min"){
            padding-top: 42px;
        }
    }
}

.--bg-black,
.--bg-red,
.--dark-mode {
    .btn {
        &__list {
            border-color: $white;

            .btn {
                &-arrow {
                    border-color: $white;
                    color: $white;

                    &__icons {
                        .icon__arrow * {
                            fill: $white;
                        }
                    }

                    &:hover, &:focus-within {
                        color: $black;
                    }
                }
            }
        }

        &-box {
            color: $black;
            background-color: $white;
            border: 2px solid $white;

            &:hover, &:focus-within {
                color: $black;
                background-color: $yellow;
                border-color: $yellow;
            }
        }
    }
}
