$navbar-animation-timming: .3s;

.header {
    position: absolute;
    inset: 0 0 auto 0;
    z-index: 400;
    width: 100%;
    height: auto;
    display: block;
    padding-top: 40px;

    $header: &;

    &__brand {
        height: 100px;
        position: relative;
        z-index: 2;
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: box-shadow $navbar-animation-timming ease-in-out, height $navbar-animation-timming ease-in-out;

        > .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-link {
            pointer-events: all;
            display: flex;
            flex-direction: column;
            width: 140px;
            max-height: 160px;
            overflow: hidden;
            transition: margin $navbar-animation-timming ease-in-out, width $navbar-animation-timming ease-in-out;

            .logo {
                width: 137px;
                fill: $black;
                transition: margin $navbar-animation-timming ease-in-out, width $navbar-animation-timming ease-in-out;

                .ego {
                    transition: opacity $navbar-animation-timming ease-in-out;
                    
                    * {
                        transition: fill $navbar-animation-timming ease-in-out;
                    }
                }

                .circle {
                    * {
                        transition: fill $navbar-animation-timming ease-in-out;
                    }
                }
            }
        }

        &-burger {
            display: none;
        }

        @include device-md {
            &-link {
                .hemplab-isologo {
                    width: 45px;
                }
            }

            .hemplab-iso {
                width: 90px;
                margin-top: 0;
            }
        }
    }

    &__nav {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        text-align: right;
        padding-top: 40px;
        transition: transform $navbar-animation-timming ease-in-out, background-color .2s ease-in-out;

        &__menu {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            &--wrapper {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 20px;
                padding-left: 200px;
                transition: border-bottom-color $navbar-animation-timming ease-in-out;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    inset: auto 0 -1px 0;
                    height: 1px;
                    background-color: $black;
                    opacity: 0;
                    transition: opacity .2s ease-in-out;
                }

                &.--fixed {
                    padding-left: 0;
                }
            }

            .btn {
                position: relative;
                color: $black;
                @include font-size(20, 26, 500);
                text-decoration: none;

                &::after {
                    content: '';
                    position: absolute;
                    inset: auto auto 0 0;
                    width: 0;
                    height: 2px;
                    border-top: 2px solid $black;
                    transition: width $navbar-animation-timming ease-in-out;
                }

                &.active,
                &.ancestor,
                &:hover {
                    &::after {
                        width: 100%;
                    }
                }
            }

            &__mobile-copy {
                display: none;
            }
        }
    }

    /* MENU FIXED */
    &.--fixed {
        position: fixed;
        padding-top: 0;

        #{$header}__brand {
            &-link {
                margin-left: -63px;
                width: 41px;

                .logo {
                    .ego {
                        opacity: 0;
                    }
                }
            }

            @include device-lg {
                height: 80px;
            }
        }

        #{$header}__nav {
            padding-top: 0;
        }

        @include device-lg("min") {
            #{$header}__nav {
                // pointer-events: none;
            }

            &.--active,
            &:hover {
                #{$header}__nav {
                    background-color: $white;
                    
                    &__menu {
                        &--wrapper {
                            &:after {
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                #{$header}__brand {
                    &-link {
                        .logo {
                            .circle * {
                                fill: $black !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.--dark-mode {
        .logo {
            .ego * {
                fill: $white;
            }
            .circle * {
                fill: $white;
            }
        }

        #{$header}__brand {
            &-burger {
                &__flipper {
                    span {
                        color: $white;
                    }
                }
            }
        }

        #{$header}__nav {
            &__menu {
                .btn {
                    color: $white;

                    &::after {
                        border-color: $white;
                    }
                }
            }
        }

        &.--fixed {
            #{$header}__nav {
                &__menu {
                    &--wrapper.--fixed {
                        .btn {
                            color: $black;
                            &:after {
                                border-color: $black;
                            }
                        }
                    }
                }
            }
        }
    }

    /* MOBILE COLLAPSE */
    @include device-lg {
        position: fixed;
        padding-top: 0;
        left: 2px;
        width: calc(100% - 2px);

        &__brand {
            background-color: transparent;
            padding-top: 20px;
            pointer-events: all;
            transition: padding $navbar-animation-timming ease-in-out, height $navbar-animation-timming ease-in-out;

            .wrapper {
                padding-left: 30px;
                @include device-md{
                    padding-left: 14px;
                }
            }

            &-link {
                margin-left: 0;
                width: auto;
            }

            &-burger {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                border: 0;
                background: none;
                outline: none;
                padding: 0 8px;
                margin: 0;
                cursor: pointer;
                @include font-size(16, 16, 700);
                $burger: &;

                &__flipper {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    transition: transform $navbar-animation-timming ease-in-out;
                    transform-style: preserve-3d;
                    backface-visibility: hidden;
                    -webkit-backface-visibility: hidden;

                    span {
                        backface-visibility: hidden;
                        -webkit-backface-visibility: hidden;
                        color: $black;
                        transition: color $navbar-animation-timming ease-in-out;
                        
                        &.txt-menu {
                            position: absolute;
                            inset: 30% 0 0 0;
                        }
    
                        &.txt-close {
                            transform: rotateX( 180deg );
                        }
                    }
                }
            }
        }

        &__nav {
            position: absolute;
            height: 100vh;
            background-color: $white;
            transform: translateY(calc(-100% - 100px));
            transition: transform $navbar-animation-timming ease-in-out;
            padding-top: 110px;

            &__menu {
                width: 100%;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                padding-bottom: 30px;
                left: 0;
                
                &--wrapper {
                    padding-left: 0px;
                    border-bottom: 0;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 16px;

                    &:after {
                        display: none;
                    }
                }

                .btn {
                    color: $black;

                    &:not(.--black) {
                        @include font-size(50, 50, 500, -2);

                        width: auto;
                        display: inline-block;
                        text-align: left;
                        border: 0;
                        text-transform: none;
                        padding-left: 0;
                        padding-right: 0;

                        &:hover,
                        &.active,
                        &.ancestor {
                            font-weight: 500;
                        }
                    }
                }

                &__mobile-copy {
                    display: block;
                    margin-top: 15px;
                    border-top: 1px solid $black;
                    padding-top: 16px;
                    text-align: left;
                    @include font-size(14, 23, 400);
                    width: 100%
                }
            }
        }

        &.--fixed {
            #{$header}__brand {
                box-shadow: none;
                background-color: transparent;
                padding-top: 0;

                &-link {
                    width: auto;
                    margin-left: 0;
                }
            }

            #{$header}__nav {
                &__menu {
                    &--wrapper {
                        border-bottom: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        /* MOBILE MENU OPENED */
        &.--active,
        &.--fixed.--active {
            #{$header}__brand {
                box-shadow: none;

                &-link {
                    .ego {
                        opacity: 1;

                        * {
                            fill: $black;
                        }
                    }
                    .circle * {
                        fill: $black;
                    }
                }

                &-burger {
                    &__flipper {
                        transform: rotateX(180deg);
                    }
                }
            }

            #{$header}__nav {
                transform: translateY(0);

                &__menu {
                    .btn {
                        color: $black;
                    }
                }
            }
        }
    }

    @include device-lg {
        &.--active {
            #{$header}__brand {
                &-burger {
                    &__flipper {
                        span {
                            color: $black;
                        }
                    }
                }
            }

            #{$header}__nav {
                width: calc(100% + 2px);
                left: -2px;
            }
        }
    }
}

.header__nav-overlay {
    position: fixed;
    z-index: 3;
    inset: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    pointer-events: none;
    transition: opacity $navbar-animation-timming ease-in-out;

    &.--active {
        opacity: 1;
        pointer-events: all;
    }
}