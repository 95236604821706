h1 {
    @include font-size(180, 180, 500, -4);
    margin-bottom: 0.3em;

    @include device-xl {
        @include font-size(120, 120, 500, -3);
    }
    
    @include device-md {
        @include font-size(70, 70, 500, -3);
    }
    @include device-sm {
        // @include font-size(70, 70, 500, -2);
        @include font-size(56, 56, 500, -3);
    }

}

h2 {
    @include font-size(100, 100, 500, -3);
    margin-bottom: 0.3em;

    @include device-xl {
        @include font-size(80, 80, 500, -3);
    }

    @include device-sm {
        @include font-size(50, 50, 500, -2);
    }
}

h3 {
    @include font-size(80, 92, 500, -3);
    margin-bottom: 0.3em;

    @include device-xl {
        @include font-size(64, 74, 500, -3);
    }

    @include device-sm {
        @include font-size(32, 40, 500, -3);
    }
}

h4 {
    @include font-size(60, 70, 400, -2);
    margin-bottom: 0.3em;

    @include device-xl {
        @include font-size(40, 50, 400, -2);
    }

    @include device-sm {
        @include font-size(24, 30, 400, -2);
    }
}

h5 {
    @include font-size(40, 48, 400, -2);
    margin-bottom: 1em;

    @include device-xl {
        @include font-size(34, 40, 400, -2);
    }

    @include device-sm {
        @include font-size(30, 36, 400);
    }
}

h1, h2, h3, h4, h5, h6 {
    mark {
        background: none;
        font-weight: 600;
        text-decoration: underline yellow 2px;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.1em;
    }
}