@font-face {
    font-family: 'Switzer';
    src: url('../fonts/switzer/Switzer-Regular.woff2') format('woff2'),
         url('../fonts/switzer/Switzer-Regular.woff') format('woff'),
         url('../fonts/switzer/Switzer-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

@font-face {
    font-family: 'Switzer';
    src: url('../fonts/switzer/Switzer-Medium.woff2') format('woff2'),
         url('../fonts/switzer/Switzer-Medium.woff') format('woff'),
         url('../fonts/switzer/Switzer-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/switzer/Switzer-Bold.woff2') format('woff2'),
         url('../fonts/switzer/Switzer-Bold.woff') format('woff'),
         url('../fonts/switzer/Switzer-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }