/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Switzer";
  src: url("../fonts/switzer/Switzer-Regular.woff2") format("woff2"), url("../fonts/switzer/Switzer-Regular.woff") format("woff"), url("../fonts/switzer/Switzer-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Switzer";
  src: url("../fonts/switzer/Switzer-Medium.woff2") format("woff2"), url("../fonts/switzer/Switzer-Medium.woff") format("woff"), url("../fonts/switzer/Switzer-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Switzer";
  src: url("../fonts/switzer/Switzer-Bold.woff2") format("woff2"), url("../fonts/switzer/Switzer-Bold.woff") format("woff"), url("../fonts/switzer/Switzer-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Switzer", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
}
@media (max-width: 1199px) {
  body {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  body {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}

.wrapper {
  display: block;
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
}
@media (max-width: 991px) {
  .wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.section {
  padding-top: 120px;
  padding-bottom: 120px;
}
.section:first-of-type {
  padding-top: 380px;
}
@media (max-width: 767px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section:first-of-type {
    padding-top: 260px;
  }
}
.section--work-case {
  padding-top: 60px;
  padding-bottom: 60px;
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

strong {
  font-weight: 600;
}

p {
  margin-bottom: 2em;
}

.feature-list-title {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
@media (max-width: 1199px) {
  .feature-list-title {
    font-size: 34px;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.1764705882;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .feature-list-title {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .feature-list-title--small {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}

.big-list-title {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
}
@media (max-width: 1199px) {
  .big-list-title {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .big-list-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}

blockquote {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
}
@media (max-width: 1199px) {
  blockquote {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  blockquote {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}

.btn-text {
  color: inherit;
  text-decoration: underline;
}
.btn-box {
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  background-color: #000;
  padding: 20px 40px;
  text-decoration: none;
  border: 2px solid #000;
  cursor: pointer;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.btn-box:hover, .btn-box:focus-within {
  color: #000;
  background-color: #FFFF00;
}
.btn-box.--loading {
  position: relative;
  color: transparent;
  pointer-events: none;
}
.btn-box.--loading .spinner {
  display: block;
  color: #fff;
}
.btn-box.--secondary {
  background-color: #fff;
  color: #000;
  border-color: #f1f1f1;
}
.btn-box.--secondary:hover, .btn-box.--secondary:focus-within {
  border-color: #000;
}
@media (max-width: 767px) {
  .btn-box {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    padding: 13px 24px;
  }
}
.btn-chevron {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: normal;
  color: inherit;
  text-decoration: underline;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, padding 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .btn-chevron {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625;
    letter-spacing: normal;
  }
}
.btn-chevron::after {
  content: "";
  display: inline-block;
  width: 0.45em;
  height: 0.45em;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform-origin: center;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.btn-chevron:hover, .btn-chevron:focus-within {
  color: #000;
  background-color: #FFFF00;
  padding-left: 8px;
  padding-right: 10px;
  text-decoration: none;
}
.btn-chevron:hover::after, .btn-chevron:focus-within::after {
  opacity: 1;
}
.btn-arrow {
  display: grid;
  grid-template-columns: auto 125px;
  gap: 12px;
  align-items: center;
  width: auto;
  min-height: 80px;
  border: 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  color: inherit;
  text-decoration: none;
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: normal;
  transition: background-color 0.2s ease-in-out, padding 0.2s ease-in-out, color 0.2s ease-in-out;
}
.btn-arrow__title {
  padding-top: 8px;
  padding-bottom: 8px;
}
.btn-arrow__icons {
  position: relative;
  height: 100%;
  text-align: right;
  transition: opacity 0.2s ease-in-out;
}
.btn-arrow__icons .icon__arrow {
  display: block;
  width: 36px;
  height: 22px;
  margin-top: -11px;
  position: absolute;
  top: 50%;
  right: 0;
  transition: opacity 0.2s ease-in-out;
}
.btn-arrow__icons i {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  text-decoration: underline;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.btn-arrow__icons i .icon__external {
  display: none;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  opacity: 1;
  vertical-align: -0.3em;
}
.btn-arrow:hover, .btn-arrow:focus-within {
  background-color: #FFFF00;
  padding-left: 16px;
  padding-right: 16px;
  color: #000;
}
.btn-arrow:hover .btn-arrow__title .tag-rounded, .btn-arrow:focus-within .btn-arrow__title .tag-rounded {
  background-color: #fff;
}
.btn-arrow:hover .btn-arrow__icons .icon__arrow, .btn-arrow:focus-within .btn-arrow__icons .icon__arrow {
  opacity: 0;
}
.btn-arrow:hover .btn-arrow__icons i, .btn-arrow:focus-within .btn-arrow__icons i {
  opacity: 1;
}
.btn-arrow[target=_blank] .btn-arrow__icons i .icon__external {
  display: inline-block;
}
@media (max-width: 767px) {
  .btn-arrow {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: normal;
    grid-template-columns: auto 50px;
    min-height: 60px;
  }
  .btn-arrow__icons .icon__arrow {
    margin-top: -8px;
    width: 27px;
    height: 16px;
  }
  .btn-arrow__icons i {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
  }
  .btn-arrow:hover .btn-arrow__icons, .btn-arrow:focus-within .btn-arrow__icons {
    opacity: 0;
  }
  .btn-arrow:hover .btn-arrow__icons i, .btn-arrow:focus-within .btn-arrow__icons i {
    opacity: 0;
  }
}
.btn-stripe {
  display: grid;
  grid-template-columns: auto 125px;
  gap: 12px;
  align-items: center;
  width: auto;
  color: inherit;
  text-decoration: none;
  outline: none;
}
@media (max-width: 767px) {
  .btn-stripe {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: normal;
    grid-template-columns: auto 50px;
  }
  .btn-stripe .btn-line__label {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
  }
}
.btn-stripe__title {
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0;
  transition: all 0.2s ease-in-out;
}
.btn-stripe__title::before {
  content: "";
  width: 0;
  margin-right: 0;
  border: none;
  border-top: 2px solid #000;
  height: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.2s ease-in-out;
}
.btn-stripe__icons {
  position: relative;
  height: 100%;
  text-align: right;
  transition: opacity 0.2s ease-in-out;
}
.btn-stripe__icons .icon__arrow {
  text-align: right;
  fill: none;
  display: block;
  width: 36px;
  height: 22px;
  margin-top: -11px;
  position: absolute;
  top: 50%;
  right: 0;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .btn-stripe__icons .icon__arrow {
    margin-top: -8px;
    width: 27px;
    height: 16px;
  }
}
@media (min-width: 1200px) {
  .btn-stripe__icons .icon__arrow {
    display: none;
  }
}
.btn-stripe__label {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  text-decoration: underline;
  text-transform: uppercase;
  opacity: 0;
  padding-right: 0;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 1199px) {
  .btn-stripe__label {
    display: none;
  }
}
@media (min-width: 768px) {
  .btn-stripe:hover .btn-stripe__title, .btn-stripe:focus-within .btn-stripe__title {
    padding-left: 74px;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .btn-stripe:hover .btn-stripe__title, .btn-stripe:focus-within .btn-stripe__title {
    padding-left: 110px;
  }
}
@media (min-width: 768px) {
  .btn-stripe:hover .btn-stripe__title::before, .btn-stripe:focus-within .btn-stripe__title::before {
    width: 54px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .btn-stripe:hover .btn-stripe__title::before, .btn-stripe:focus-within .btn-stripe__title::before {
    width: 70px;
  }
}
@media (min-width: 768px) {
  .btn-stripe:hover .btn-stripe__label, .btn-stripe:focus-within .btn-stripe__label {
    padding-right: 16px;
    opacity: 1;
  }
}

.btn__list {
  border-top: 1px solid #000;
}
.btn__list .btn-arrow {
  border-top: 0;
}
.btn__list-big {
  display: block;
}
.btn__list-big li {
  margin-bottom: 16px;
}
@media (min-width: 1200px) {
  .btn__list-big li {
    margin-bottom: 24px;
  }
}
.btn__list-big li:last-child {
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .btn__list-big {
    padding-top: 42px;
  }
}

.--bg-black .btn__list,
.--bg-red .btn__list,
.--dark-mode .btn__list {
  border-color: #fff;
}
.--bg-black .btn__list .btn-arrow,
.--bg-red .btn__list .btn-arrow,
.--dark-mode .btn__list .btn-arrow {
  border-color: #fff;
  color: #fff;
}
.--bg-black .btn__list .btn-arrow__icons .icon__arrow *,
.--bg-red .btn__list .btn-arrow__icons .icon__arrow *,
.--dark-mode .btn__list .btn-arrow__icons .icon__arrow * {
  fill: #fff;
}
.--bg-black .btn__list .btn-arrow:hover, .--bg-black .btn__list .btn-arrow:focus-within,
.--bg-red .btn__list .btn-arrow:hover,
.--bg-red .btn__list .btn-arrow:focus-within,
.--dark-mode .btn__list .btn-arrow:hover,
.--dark-mode .btn__list .btn-arrow:focus-within {
  color: #000;
}
.--bg-black .btn-box,
.--bg-red .btn-box,
.--dark-mode .btn-box {
  color: #000;
  background-color: #fff;
  border: 2px solid #fff;
}
.--bg-black .btn-box:hover, .--bg-black .btn-box:focus-within,
.--bg-red .btn-box:hover,
.--bg-red .btn-box:focus-within,
.--dark-mode .btn-box:hover,
.--dark-mode .btn-box:focus-within {
  color: #000;
  background-color: #FFFF00;
  border-color: #FFFF00;
}

.header {
  position: absolute;
  inset: 0 0 auto 0;
  z-index: 400;
  width: 100%;
  height: auto;
  display: block;
  padding-top: 40px;
  /* MENU FIXED */
  /* MOBILE COLLAPSE */
}
.header__brand {
  height: 100px;
  position: relative;
  z-index: 2;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out, height 0.3s ease-in-out;
}
.header__brand > .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__brand-link {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  width: 140px;
  max-height: 160px;
  overflow: hidden;
  transition: margin 0.3s ease-in-out, width 0.3s ease-in-out;
}
.header__brand-link .logo {
  width: 137px;
  fill: #000;
  transition: margin 0.3s ease-in-out, width 0.3s ease-in-out;
}
.header__brand-link .logo .ego {
  transition: opacity 0.3s ease-in-out;
}
.header__brand-link .logo .ego * {
  transition: fill 0.3s ease-in-out;
}
.header__brand-link .logo .circle * {
  transition: fill 0.3s ease-in-out;
}
.header__brand-burger {
  display: none;
}
@media (max-width: 767px) {
  .header__brand-link .hemplab-isologo {
    width: 45px;
  }
  .header__brand .hemplab-iso {
    width: 90px;
    margin-top: 0;
  }
}
.header__nav {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  text-align: right;
  padding-top: 40px;
  transition: transform 0.3s ease-in-out, background-color 0.2s ease-in-out;
}
.header__nav__menu {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.header__nav__menu--wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
  padding-left: 200px;
  transition: border-bottom-color 0.3s ease-in-out;
}
.header__nav__menu--wrapper:after {
  content: "";
  display: block;
  position: absolute;
  inset: auto 0 -1px 0;
  height: 1px;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.header__nav__menu--wrapper.--fixed {
  padding-left: 0;
}
.header__nav__menu .btn {
  position: relative;
  color: #000;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: normal;
  text-decoration: none;
}
.header__nav__menu .btn::after {
  content: "";
  position: absolute;
  inset: auto auto 0 0;
  width: 0;
  height: 2px;
  border-top: 2px solid #000;
  transition: width 0.3s ease-in-out;
}
.header__nav__menu .btn.active::after, .header__nav__menu .btn.ancestor::after, .header__nav__menu .btn:hover::after {
  width: 100%;
}
.header__nav__menu__mobile-copy {
  display: none;
}
.header.--fixed {
  position: fixed;
  padding-top: 0;
}
.header.--fixed .header__brand-link {
  margin-left: -63px;
  width: 41px;
}
.header.--fixed .header__brand-link .logo .ego {
  opacity: 0;
}
@media (max-width: 991px) {
  .header.--fixed .header__brand {
    height: 80px;
  }
}
.header.--fixed .header__nav {
  padding-top: 0;
}
@media (min-width: 992px) {
  .header.--fixed.--active .header__nav, .header.--fixed:hover .header__nav {
    background-color: #fff;
  }
  .header.--fixed.--active .header__nav__menu--wrapper:after, .header.--fixed:hover .header__nav__menu--wrapper:after {
    display: block;
    opacity: 1;
  }
  .header.--fixed.--active .header__brand-link .logo .circle *, .header.--fixed:hover .header__brand-link .logo .circle * {
    fill: #000 !important;
  }
}
.header.--dark-mode .logo .ego * {
  fill: #fff;
}
.header.--dark-mode .logo .circle * {
  fill: #fff;
}
.header.--dark-mode .header__brand-burger__flipper span {
  color: #fff;
}
.header.--dark-mode .header__nav__menu .btn {
  color: #fff;
}
.header.--dark-mode .header__nav__menu .btn::after {
  border-color: #fff;
}
.header.--dark-mode.--fixed .header__nav__menu--wrapper.--fixed .btn {
  color: #000;
}
.header.--dark-mode.--fixed .header__nav__menu--wrapper.--fixed .btn:after {
  border-color: #000;
}
@media (max-width: 991px) {
  .header {
    position: fixed;
    padding-top: 0;
    left: 2px;
    width: calc(100% - 2px);
    /* MOBILE MENU OPENED */
  }
  .header__brand {
    background-color: transparent;
    padding-top: 20px;
    pointer-events: all;
    transition: padding 0.3s ease-in-out, height 0.3s ease-in-out;
  }
  .header__brand .wrapper {
    padding-left: 30px;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .header__brand .wrapper {
    padding-left: 14px;
  }
}
@media (max-width: 991px) {
  .header__brand-link {
    margin-left: 0;
    width: auto;
  }
  .header__brand-burger {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 0;
    background: none;
    outline: none;
    padding: 0 8px;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: normal;
  }
  .header__brand-burger__flipper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    transition: transform 0.3s ease-in-out;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .header__brand-burger__flipper span {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    color: #000;
    transition: color 0.3s ease-in-out;
  }
  .header__brand-burger__flipper span.txt-menu {
    position: absolute;
    inset: 30% 0 0 0;
  }
  .header__brand-burger__flipper span.txt-close {
    transform: rotateX(180deg);
  }
  .header__nav {
    position: absolute;
    height: 100vh;
    background-color: #fff;
    transform: translateY(calc(-100% - 100px));
    transition: transform 0.3s ease-in-out;
    padding-top: 110px;
  }
  .header__nav__menu {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 30px;
    left: 0;
  }
  .header__nav__menu--wrapper {
    padding-left: 0px;
    border-bottom: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
  }
  .header__nav__menu--wrapper:after {
    display: none;
  }
  .header__nav__menu .btn {
    color: #000;
  }
  .header__nav__menu .btn:not(.--black) {
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    width: auto;
    display: inline-block;
    text-align: left;
    border: 0;
    text-transform: none;
    padding-left: 0;
    padding-right: 0;
  }
  .header__nav__menu .btn:not(.--black):hover, .header__nav__menu .btn:not(.--black).active, .header__nav__menu .btn:not(.--black).ancestor {
    font-weight: 500;
  }
  .header__nav__menu__mobile-copy {
    display: block;
    margin-top: 15px;
    border-top: 1px solid #000;
    padding-top: 16px;
    text-align: left;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.6428571429;
    letter-spacing: normal;
    width: 100%;
  }
  .header.--fixed .header__brand {
    box-shadow: none;
    background-color: transparent;
    padding-top: 0;
  }
  .header.--fixed .header__brand-link {
    width: auto;
    margin-left: 0;
  }
  .header.--fixed .header__nav__menu--wrapper {
    border-bottom: 0;
  }
  .header.--fixed .header__nav__menu--wrapper:after {
    display: none;
  }
  .header.--active .header__brand, .header.--fixed.--active .header__brand {
    box-shadow: none;
  }
  .header.--active .header__brand-link .ego, .header.--fixed.--active .header__brand-link .ego {
    opacity: 1;
  }
  .header.--active .header__brand-link .ego *, .header.--fixed.--active .header__brand-link .ego * {
    fill: #000;
  }
  .header.--active .header__brand-link .circle *, .header.--fixed.--active .header__brand-link .circle * {
    fill: #000;
  }
  .header.--active .header__brand-burger__flipper, .header.--fixed.--active .header__brand-burger__flipper {
    transform: rotateX(180deg);
  }
  .header.--active .header__nav, .header.--fixed.--active .header__nav {
    transform: translateY(0);
  }
  .header.--active .header__nav__menu .btn, .header.--fixed.--active .header__nav__menu .btn {
    color: #000;
  }
}
@media (max-width: 991px) {
  .header.--active .header__brand-burger__flipper span {
    color: #000;
  }
  .header.--active .header__nav {
    width: calc(100% + 2px);
    left: -2px;
  }
}

.header__nav-overlay {
  position: fixed;
  z-index: 3;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.header__nav-overlay.--active {
  opacity: 1;
  pointer-events: all;
}

h1 {
  font-size: 180px;
  font-size: 11.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
  margin-bottom: 0.3em;
}
@media (max-width: 1199px) {
  h1 {
    font-size: 120px;
    font-size: 7.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 70px;
    font-size: 4.375rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 56px;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}

h2 {
  font-size: 100px;
  font-size: 6.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.03em;
  margin-bottom: 0.3em;
}
@media (max-width: 1199px) {
  h2 {
    font-size: 80px;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
  }
}

h3 {
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
  margin-bottom: 0.3em;
}
@media (max-width: 1199px) {
  h3 {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  h3 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
  }
}

h4 {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.02em;
  margin-bottom: 0.3em;
}
@media (max-width: 1199px) {
  h4 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  h4 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}

h5 {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-bottom: 1em;
}
@media (max-width: 1199px) {
  h5 {
    font-size: 34px;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.1764705882;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  h5 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: normal;
  }
}

h1 mark, h2 mark, h3 mark, h4 mark, h5 mark, h6 mark {
  background: none;
  font-weight: 600;
  text-decoration: underline yellow 2px;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.1em;
}